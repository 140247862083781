/* import __COLOCATED_TEMPLATE__ from './secure-messenger.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { INSTALLATION_DOCS_URL } from 'embercom/lib/installation-docs-url';
import {
  type InstallationPlatform,
  INSTALLATION_METHOD,
  INSTALLATION_PLATFORMS,
  PROGRAMMING_LANGUAGES,
} from 'embercom/components/installation-new/constants';
import type Store from '@ember-data/store';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { post } from 'embercom/lib/ajax';
import type RouterService from '@ember/routing/router-service';
import { taskFor } from 'ember-concurrency-ts';
import SdkApp from 'embercom/models/sdk-app';

type TabValue = InstallationPlatform | 'react-native';

export interface Args {
  selectedTab: TabValue;
  setSelectedTab: (tab: TabValue) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}
export default class SecureMessenger extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare messengerInstallation: MessengerInstallation;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare identityVerificationRiskStateService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @tracked selectedFrontendInstallationMethod = INSTALLATION_METHOD.CODE_SNIPPET;
  @tracked selectedBackendInstallationMethod = PROGRAMMING_LANGUAGES.NODE;
  @tracked selectedMobilePlatform: TabValue = 'ios';
  @tracked showIdentityVerificationConfirmationModal = false;
  @tracked showIdentityVerificationDisablingModal = false;
  @tracked isTokenDecoderOpen = false;
  @tracked pingDomainsCount = 0;
  @tracked pingDomainsUnresolvedErrorCount = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadIdentityVerificationKeys();
    this.loadSdkApps();

    if (
      args.selectedTab === 'ios' ||
      args.selectedTab === 'android' ||
      args.selectedTab === 'react-native'
    ) {
      this.selectedMobilePlatform = args.selectedTab;
    }
  }

  private async loadIdentityVerificationKeys() {
    await this.store.findAll('messenger-security/idv-setting');
  }

  private async loadSdkApps() {
    try {
      this.iosSdkApp = await SdkApp.findOrCreateForPlatform('iOS');
      this.androidSdkApp = await SdkApp.findOrCreateForPlatform('Android');
    } catch (error) {
      console.error('Error loading SDK apps:', error);
      this.notificationsService.notifyError(
        this.intl.t('messenger.security-settings.secure-messenger.error-loading-sdk-apps'),
      );
    }
  }

  @tracked iosSdkApp!: SdkApp;
  @tracked androidSdkApp!: SdkApp;

  get selectedTab() {
    return this.args.selectedTab || 'web';
  }

  get selectedInstallationPlatform() {
    return this.selectedTab === 'web' ? 'web' : 'mobile';
  }

  get app() {
    return this.appService.app;
  }

  get docsUrl() {
    return INSTALLATION_DOCS_URL;
  }

  get isIdvEnabled(): boolean {
    if (this.selectedTab === 'web') {
      return this.app.identityVerified;
    } else if (this.selectedTab === 'ios') {
      return this.iosSdkApp?.identityVerified || false;
    } else if (this.selectedTab === 'android') {
      return this.androidSdkApp?.identityVerified || false;
    }
    return false;
  }

  get frontendInstallationMethods() {
    let baseInstallationMethods = [
      {
        id: INSTALLATION_METHOD.CODE_SNIPPET,
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.methods.javascript-label',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-code.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.REACT,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.react-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/react.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.react.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.react.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.ANGULAR,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.angular-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/angular.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.angular.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.angular.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.VUE,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.vue-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/vue.svg'),
        instructionsTitle: this.intl.t('apps.app.settings.installation.installation.new.vue.title'),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.vue.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.RAILS_GEM,
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.code-snippet.app-types.rails-gem',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/rails.svg'),
      },
      {
        id: INSTALLATION_METHOD.EMBER,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.ember-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/ember.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.ember.title',
        ),
        instructionsSubTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.ember.sub-title',
        ),
      },
      {
        id: INSTALLATION_METHOD.WORDPRESS,
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.methods.wordpress-label',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-wordpress.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.wordpress.title',
        ),
      },
      {
        id: INSTALLATION_METHOD.GOOGLE_TAG_MANAGER,
        label: this.intl.t(
          'apps.app.settings.installation.installation.new.methods.google-tag-manager-label',
        ),
        iconUrl: assetUrl(
          '/assets/images/settings/messenger-installation-new/icon-googletagmanager.svg',
        ),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.google-tag-manager.title',
        ),
      },
      {
        id: INSTALLATION_METHOD.SHOPIFY,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.shopify-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-shopify.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.shopify.title',
        ),
      },
      {
        id: INSTALLATION_METHOD.SEGMENT,
        label: this.intl.t('apps.app.settings.installation.installation.new.methods.segment-label'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/icon-segment.svg'),
        instructionsTitle: this.intl.t(
          'apps.app.settings.installation.installation.new.segment.title',
        ),
      },
    ];

    return baseInstallationMethods;
  }

  get backendInstallationMethods() {
    return [
      {
        id: PROGRAMMING_LANGUAGES.NODE,
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.programming-languages.node',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/node-js.svg'),
      },
      {
        id: PROGRAMMING_LANGUAGES.RAILS,
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.programming-languages.rails',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/rails.svg'),
      },
      {
        id: PROGRAMMING_LANGUAGES.DJANGO,
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.programming-languages.django',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/django.svg'),
      },
      {
        id: PROGRAMMING_LANGUAGES.PHP,
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.programming-languages.php',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/php.svg'),
      },
      {
        id: PROGRAMMING_LANGUAGES.GO,
        label: this.intl.t('messenger.security-settings.secure-messenger.programming-languages.go'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/go.svg'),
      },
      {
        id: PROGRAMMING_LANGUAGES.JAVA,
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.programming-languages.java',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/java.svg'),
      },
    ];
  }

  get unsupportedInstallationPlatform() {
    let unsupportedInstallationPlatforms = [
      INSTALLATION_METHOD.WORDPRESS,
      INSTALLATION_METHOD.GOOGLE_TAG_MANAGER,
      INSTALLATION_METHOD.SHOPIFY,
      INSTALLATION_METHOD.SEGMENT,
    ];
    return unsupportedInstallationPlatforms.includes(this.selectedFrontendInstallationMethod);
  }

  @action setSelectedFrontendInstallationMethod(installationMethod: INSTALLATION_METHOD) {
    this.selectedFrontendInstallationMethod = installationMethod;
  }

  @action setSelectedBackendInstallationMethod(installationMethod: PROGRAMMING_LANGUAGES) {
    this.selectedBackendInstallationMethod = installationMethod;
  }

  @action
  setTabSelection(tab: TabValue) {
    this.args.setSelectedTab(tab);
  }

  @action
  setSelectedInstallationPlatform(platform: 'web' | 'mobile') {
    if (platform === 'mobile') {
      this.args.setSelectedTab(INSTALLATION_PLATFORMS.IOS);
    } else {
      this.args.setSelectedTab(INSTALLATION_PLATFORMS.WEB);
    }
  }

  @action
  setSelectedMobilePlatform(platform: TabValue) {
    this.selectedMobilePlatform = platform;

    this.router.transitionTo({
      queryParams: {
        section: 'messenger-security',
        tab: platform,
      },
    });
  }

  @action
  toggleIdentityVerification() {
    if (this.isIdvEnabled) {
      this.showIdentityVerificationDisablingModal = true;
    } else {
      this.showIdentityVerificationConfirmationModal = true;
    }
  }

  get displayUserApiDisabledError() {
    return this.app.isWebMessengerDisabled;
  }

  @action
  async enableUserTrafficApi() {
    await taskFor(this.messengerInstallation.togglePlatformApiAvailability).perform('web', false);
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'apps.app.settings.installation.installation.new.web.setup-idv.check-installation-step.user-traffic-enabled',
      ),
    );
  }

  @action
  async enableIdentityVerification() {
    if (this.selectedTab === 'web') {
      this.updateIdentityVerificationStatusForWeb(true);
    } else {
      await this.updateIdentityVerificationStatusForMobile(true);
    }
    this.identityVerificationRiskStateService.start(this.app);

    this.showIdentityVerificationConfirmationModal = false;
  }

  @action
  async disableIdentityVerification() {
    if (this.selectedTab === 'web') {
      this.updateIdentityVerificationStatusForWeb(false);
    } else {
      await this.updateIdentityVerificationStatusForMobile(false);
    }
    this.identityVerificationRiskStateService.start(this.app);

    this.showIdentityVerificationDisablingModal = false;
  }

  private async updateIdentityVerificationStatusForWeb(isEnabled: boolean) {
    if (
      !isEnabled &&
      this.helpCenterService.allLiveSites.some((site: HelpCenterSite) => site.ticketsPortalTurnedOn)
    ) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.installation.identity-verification.cannot-disable-idv'),
      );
      return;
    }

    post(`/ember/apps/${this.app.id}/update_identity_verification`, {
      identity_verified: isEnabled,
    });

    this.app.identityVerified = isEnabled;
    this.messengerInstallation.isIdvConfiguredForWeb = isEnabled;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'enforce_jwt_toggle',
      metadata: {
        enabled: isEnabled,
        platform: 'web',
        has_jwt_traffic: this.hasSeenJwtTraffic,
        has_unresolved_errors: this.hasUnresolvedJwtErrorsTraffic,
        jwt_requests_count: this.pingDomainsCount,
        unresolved_errors_count: this.pingDomainsUnresolvedErrorCount,
      },
      place: 'messenger-settings',
      section: 'security',
    });
  }

  private async updateIdentityVerificationStatusForMobile(isEnabled: boolean) {
    let sdkApp;

    if (this.selectedTab === 'ios') {
      sdkApp = this.iosSdkApp;
    } else if (this.selectedTab === 'android') {
      sdkApp = this.androidSdkApp;
    }

    if (!sdkApp) {
      console.error(`SDK app not found for platform: ${this.selectedTab}`);
      this.notificationsService.notifyError(
        this.intl.t('messenger.security-settings.secure-messenger.sdk-app-not-found'),
      );
      return;
    }

    sdkApp.identityVerified = isEnabled;
    try {
      await sdkApp.save();
    } catch (error) {
      console.error(`Error saving SDK app: ${error}`);
      this.notificationsService.notifyError(
        this.intl.t('messenger.security-settings.secure-messenger.error-saving-sdk-app'),
      );
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'enforce_jwt_toggle',
      metadata: {
        enabled: isEnabled,
        platform: this.selectedTab,
        has_jwt_traffic: this.hasSeenJwtTraffic,
        has_unresolved_errors: this.hasUnresolvedJwtErrorsTraffic,
        jwt_requests_count: this.pingDomainsCount,
        unresolved_errors_count: this.pingDomainsUnresolvedErrorCount,
      },
      place: 'messenger-settings',
      section: 'security',
    });
  }

  get idvKeys() {
    return this.store.peekAll('messenger-security/idv-setting').filter((key) => !key.deleted);
  }

  get filteredIdvKeys() {
    if (this.selectedTab === 'web') {
      return this.idvKeys.filter((key) => key.supportsWeb);
    } else if (this.selectedTab === INSTALLATION_PLATFORMS.IOS) {
      return this.idvKeys.filter((key) => key.supportsIos);
    } else if (this.selectedTab === INSTALLATION_PLATFORMS.ANDROID) {
      return this.idvKeys.filter((key) => key.supportsAndroid);
    }
    // If it's mobile but not specifically iOS or Android, show all mobile-supported keys
    return this.idvKeys.filter((key) => key.supportsIos || key.supportsAndroid);
  }

  @action
  openTokenDecoder() {
    this.isTokenDecoderOpen = true;
  }

  @action
  closeTokenDecoder() {
    this.isTokenDecoderOpen = false;
  }

  @action
  navigateToMobileIdv() {
    this.router.transitionTo('apps.app.settings.channels.messenger.install', {
      queryParams: {
        section: 'mobile-idv-setup',
        tab: 'ios',
      },
    });
  }

  @action
  onPingDomainsCountChange(count: number) {
    this.pingDomainsCount = count;
  }

  @action
  onPingDomainsUnresolvedErrorCountChange(count: number) {
    this.pingDomainsUnresolvedErrorCount = count;
  }

  get hasUnresolvedJwtErrorsTraffic() {
    return this.pingDomainsUnresolvedErrorCount > 0;
  }

  get hasSeenJwtTraffic() {
    return this.pingDomainsCount > 0;
  }

  get toggleIdentityVerificationIsDisabled() {
    // We must have seen at least one JWT request
    // JS API must be enabled
    return this.pingDomainsCount === 0 || this.displayUserApiDisabledError;
  }

  get attributesPageUrl() {
    return this.router.urlFor('apps.app.settings.data.people', {
      queryParams: { tab: 'attributes' },
    });
  }

  get railsGemConfigExample(): string {
    return `config.api_secret = Rails.application.secrets.intercom_api_secret
config.jwt.enabled = true
config.jwt.signed_user_fields = [
  "email",                 # optional
  "sensitive_attribute_1", # optional
  "sensitive_attribute_2"  # optional
]
config.jwt.expiry = 1.hour`;
  }

  get railsSecretsExample(): string {
    return 'production: intercom_api_secret: <YOUR_API_SECRET>';
  }

  get mobilePlatformMethods() {
    return [
      {
        id: 'ios',
        label: this.intl.t('messenger.security-settings.secure-messenger.mobile.platform.ios'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/apple.svg'),
        instructionsTitle: this.intl.t(
          'messenger.security-settings.secure-messenger.mobile.ios.step-1',
        ),
      },
      {
        id: 'android',
        label: this.intl.t('messenger.security-settings.secure-messenger.mobile.platform.android'),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/android.svg'),
        instructionsTitle: this.intl.t(
          'messenger.security-settings.secure-messenger.mobile.android.step-1',
        ),
      },
      {
        id: 'react-native',
        label: this.intl.t(
          'messenger.security-settings.secure-messenger.mobile.platform.react-native',
        ),
        iconUrl: assetUrl('/assets/images/settings/messenger-installation-new/react.svg'),
        instructionsTitle: this.intl.t(
          'messenger.security-settings.secure-messenger.mobile.react-native.title',
        ),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::SecureMessenger': typeof SecureMessenger;
  }
}
